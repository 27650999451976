import * as moment from 'moment';

import {IRevenueItem} from "@app/pages/revenue/types/revenue-response.interface";


export function setRevenueFromApiModel(apiModel: any): IRevenueItem {
  const revenue: IRevenueItem = {
    isExclusiveProject: apiModel.song.project?.artistCategory === 'exclusive',
    song: apiModel?.song?.name,
    artist: apiModel.artistName,
    date: apiModel.date ? moment(apiModel.date, 'YYYY/MM/DD') : null,
    rights: '',
    revenue: apiModel.revenue,
    share: apiModel.share,
    advance: apiModel.advance,
    files: apiModel.files,
    //statements: apiModel.statements,
    takenDown: apiModel.takenDown,
    projectTitle: apiModel.projectTitle,
    isOpen: apiModel.isOpen,
    artistName: apiModel.artistName,
    showTooltip: apiModel.showTooltip,
    isAlbum: apiModel.isAlbum,
    revenueHistory: apiModel.revenueHistory,
    slug: apiModel.slug,
    leftShare: apiModel.soldShare ? apiModel.share - apiModel.soldShare : 0,
    cardArtistTitle: apiModel.cardArtistTitle,
    isBundle: apiModel.isBundle,
    albumSongName: apiModel.songName,
    isTax: apiModel.isTax,
  };

  const rights = [];
  if (apiModel.royaltiesFromMaster) {
    rights.push('Master');
  }
  if (apiModel.royaltiesFromPublishingRights) {
    rights.push('Publishing');
  }
  revenue.rights = rights.join(' & ') + ' Rights';

  if (revenue.isExclusiveProject) {
    revenue.rights = 'Grand Rights';
  }

  return revenue;
}
