<div
  class="button card__end"
  role="alert"
  aria-live="polite"
  [style.backgroundColor]="item?.cardColor"
  *ngIf="showCardStatus"
>
  <ng-container *ngIf="isSoldOrFunded">
    <span>Sold out</span>
  </ng-container>

  <span *ngIf="item?.isFailed">Failed</span>

  <span *ngIf="item?.releaseCreated">
   <ng-container *ngIf="isExclusiveProject; else releaseDate">
      In Production
    </ng-container>
  </span>
</div>
<ng-template #releaseDate="">
  Released on {{ item?.releaseCreatedDate | momentDateFormat }}
</ng-template>
