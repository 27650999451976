export const QUESTION_MARK: any = {
  publish:`
          You will receive your first revenues from  <b>publishing rights</b> (radio & TV) up to 1.5
          years after the track is played on radio or TV, followed by annual payouts.`,
  master: `
          You will receive your first revenues from <b>master rights</b> (streams & downloads)
          approx. 4 months after the release, followed by monthly payouts`,
}

export const EXCLUSIVE_STATUS: string = 'You will receive your first revenues from the pro-rata box-office remuneration of the Publisher’s Net Proceeds once the Work is exploited in musical and theatres worldwide (“Grand Rights”)';
