export const ARTIST_CONTENT: any = [
  {
    icon: 'assets/images/project/musical-notes.png',
    title: 'FUND YOUR MUSIC',
    text: 'Submit your music to Global Rockstar for the chance to finance the production & marketing via crowdinvesting. Stay in control of the buildup of your career while getting support to drive success.',
  },
  {
    icon: 'assets/images/project/handshake.png',
    title: 'RECEIVE AN OFFER',
    text: 'If we feel it is a good match you will be offered different options for the funding of your music. Ranging from one- or multi-track funding deals to one of the most artist-friendly label contracts.',
  },
  {
    icon: 'assets/images/project/chart-rise.png',
    title: 'LIFT UP YOUR CAREER',
    text: 'With Global Rockstar you don’t just get the necessary financial foundation for your work but an experienced team helping you to reach your goals while opening up doors & possibilities.',
  },
  {
    icon: 'assets/images/project/globe.png',
    title: 'BUILD YOUR CAREER',
    text: 'Coming soon you will be able to build your career step by step with directly bookable services ranging from music production over playlist promotion to social media advertising.',
  },
];

export const FANS_CONTENT: any = [
  {
    icon: 'assets/images/project/hand.png',
    title: 'INVEST IN MUSIC',
    text: 'Get your shares in music rights \n and support artists in their development & future success. \nBe part of the #1 crisis-resistant \nand most fun asset class out there: music.',
  },
  {
    icon: 'assets/images/project/coins.png',
    title: 'EARN ROYALTIES',
    text: 'Your investment unlocks your share of all royalties generated through the commercial exploitation of a song from 50+ streaming stores, radio plays & live performances - worldwide.',
  },
  {
    icon: 'assets/images/project/calendar-home.png',
    title: 'BENEFIT FOR 70 YEARS',
    text: 'Receive your earnings in monthly payouts and withdraw them or re-invest them in other opportunities - for up to 70 years. All secured immutable and perfectly traceable in the blockchain.',
  }
];

export const SUCCES_CONTENT: any = [
  {
    image: 'assets/images/homepage/covers/steger.webp',
    year: '2021',
    name: 'CHRIS STEGER',
    song: 'Zefix',
    url: 'https://musicgrs.com/zefixsingle.OWE',
    detailsIcon: 'assets/images/project/coins-white.png',
    detailsValue: '>1400%',
    detailsText: 'ROI in 2 years',
    detailsColor: '#0D8AA5',
  },
  {
    icon: 'assets/images/project/hand-white.png',
    value: '€1.5M',
    text: 'Funds Raised',
  },
  {
    image: 'assets/images/homepage/covers/cest-la-vie.webp',
    year: '2021',
    name: 'ZOË',
    song: 'C’est La Vie',
    url: 'https://musicgrs.com/cestlavie.OWE',
    detailsIcon: 'assets/images/project/play-white.png',
    detailsValue: '>6.5M',
    detailsText: 'Spotify Streams',
    detailsColor: '#A5280D',
  },
  {
    icon: 'assets/images/project/handshake-white.png',
    value: '>100k',
    text: 'Smart Contracts',
  },
  {
    image: 'assets/images/homepage/covers/cambodia.webp',
    year: '2021',
    name: 'ANNA-SOPHIE',
    song: 'Cambodia',
    url: 'https://musicgrs.com/cambodia.OWE',
    detailsIcon: 'assets/images/project/coins-white.png',
    detailsValue: '>400%',
    detailsText: 'ROI in 2 years',
    detailsColor: '#FF00E5',
  },
  {
    icon: 'assets/images/project/coins-white.png',
    value: '>€700k',
    text: 'Royalties Distributed',
  },

  {
    image: 'assets/images/homepage/covers/anxiety.webp',
    year: '2023',
    name: 'FELICIA LU',
    song: 'Anxiety',
    url: 'https://felicialu.ffm.to/anxiety.OWE',
    detailsIcon: 'assets/images/project/play-white.png',
    detailsValue: '>2.2M',
    detailsText: 'Spotify Streams',
    detailsColor: '#FFA800',
  },
  {
    icon: 'assets/images/project/musical-notes-white.png',
    value: '>500',
    text: 'Songs Funded & Released',
  },

  {
    image: 'assets/images/homepage/covers/steger-album.webp',
    year: '2021',
    name: 'CHRIS STEGER',
    song: 'Zefix - The Album',
    url: 'https://musicgrs.com/zefix.OWE',
    detailsIcon: 'assets/images/project/badge-white.png',
    detailsValue: 'GOLD',
    detailsText: 'IFPI Austria',
    detailsColor: '#D4AF37',
  },
];

export const ABOUT_CONTENT: any = [
  {
    icon: 'assets/images/project/pyramid-yellow.png',
    title: 'MUSIC RIGHTS MARKETPLACE',
    text: 'Global Rockstar is the easiest way for everybody to own, collect and trade (coming 2024) music shares & music collectibles.',
  },
  {
    icon: 'assets/images/project/vinyl-yellow.png',
    title: 'MUSIC LABEL',
    text: 'With >400 released songs since 2018, Global Rockstar runs one of the fastest-growing independent music labels in Central Europe.',
  },
  {
    icon: 'assets/images/project/microphone-yellow.png',
    title: 'MUSIC PUBLISHING',
    text: 'Global Rockstar owns a music publishing company to secure, manage and commercially exploit the underlying copyrights of owned recording.',
  },
];
