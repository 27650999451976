import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatSelectModule} from "@angular/material/select";
import {MatInputModule} from "@angular/material/input";
import {MatRadioModule} from "@angular/material/radio";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {CheckBoxComponent} from "@app/components/global/checkbox/checkbox.component";
import { TooltipCustomComponent } from './tooltip/tooltip.component';
import {TimerComponent} from "@app/components/global/timer/timer.component";
import {PipesModule} from "@app/pipes/pipes.module";
import {ReleaseDateViewComponent} from "@app/components/global/release-date-view/release=date-view.component";

@NgModule({
  exports: [
    CheckBoxComponent,
    TooltipCustomComponent,
    TimerComponent,
    ReleaseDateViewComponent
  ],
  declarations: [
    CheckBoxComponent,
    TooltipCustomComponent,
    TimerComponent,
    ReleaseDateViewComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatInputModule,
    MatRadioModule,
    MatSlideToggleModule,
    PipesModule
  ],
})
export class UiModule {
}
