import { STATE_NAMES, STATE_NAMES_ALIAS } from './state-names';
export const NAVIGATION: any = {
  APP: [
    { name: 'Opportunities', path: `/${STATE_NAMES.opportunity}` },
    { name: 'For Investors', path: `/${STATE_NAMES.howItWorks}` },
    { name: 'For Artists', path: `/${STATE_NAMES_ALIAS.forArtists}` },
  ],
  PROFILE: [
    { name: 'Dashboard', path: STATE_NAMES.dashboard },
    { name: 'My Opportunities', path: STATE_NAMES.myProjects, permitions: ['projectCreationAllowed', 'artist'] },
    { name: 'My Music Assets ', path: STATE_NAMES.nft },
    { name: 'My Revenues', path: STATE_NAMES.revenue },
    { name: 'My Investments', path: STATE_NAMES.investments },
    { name: 'My Transactions', path: STATE_NAMES.balance },
    // { name: 'My Bids', path: STATE_NAMES.myAuction },
    { name: 'Profile', path: STATE_NAMES.profile },
    { name: 'Log Out', path: STATE_NAMES.logout, icon: 'assets/images/icons-common/logout.svg' },
  ],
  LOGIN: STATE_NAMES.login,
  REGISTER: STATE_NAMES.register,
  LOGOUT: STATE_NAMES.logout,
  HOME: STATE_NAMES.home,
  OPPORTUNITIES: STATE_NAMES.opportunity,
};
