import {
  ChangeDetectionStrategy,
  Component,
  Input
} from '@angular/core';
import {PROJECT_TYPE} from "@app/common/content/artist-category";

interface CardItem {
  cardColor: string;
  isSold: boolean;
  isFunded: boolean;
  releaseCreated: boolean;
  releaseCreatedDate: string;
  isFailed: boolean;
  artistCategory: string;
  statusCard?: string;
}

@Component({
  selector: 'app-card-footer',
  templateUrl: './card-footer.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardFooterComponent {
  @Input() item: CardItem | null = null;
  get isSoldOrFunded(): boolean {
    return (
      (this.item?.isSold || this.item?.isFunded) &&
      !this.item?.releaseCreated &&
      !this.item?.isFailed
    );
  }

  get isExclusiveProject(): boolean {
    return this.item.artistCategory === PROJECT_TYPE.exclusive;
  }

  get showCardStatus(): boolean {
    return !!(
      this.item?.isSold ||
      this.item?.isFunded ||
      this.item?.releaseCreated ||
      this.item?.isFailed
    );
  }
}
