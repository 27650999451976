import {CommonModule, DecimalPipe} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {UiModule} from "@app/components/global/ui.module";
import {CardProjectComponent} from "@app/components/cards/card-project/card-project.component";
import {HeaderCardComponent} from "@app/components/cards/UI/header-card/header-card.component";

import {PipesModule} from "@app/pipes/pipes.module";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {CardTooltipRightsComponent} from "@app/components/cards/UI/card-tooltip-rights/card-tooltip-rights.component";
import {CardFooterComponent} from "@app/components/cards/card-footer/card-footer.component";
@NgModule({
  exports: [
  CardProjectComponent,
    CardTooltipRightsComponent,
    HeaderCardComponent,
    CardFooterComponent

  ],
  declarations: [
  CardProjectComponent,
    CardFooterComponent,
   // TimerComponent,
    CardTooltipRightsComponent,
    HeaderCardComponent
  ],
  imports: [
    CommonModule,
  //   FormsModule,
  //   ReactiveFormsModule,
  //   GooglePayButtonModule,
    UiModule,
   PipesModule,
   RouterModule,
    MatProgressSpinnerModule
    //   UiModule,
  //   AppFormsModule,
    // MatSelectModule,
    // MatInputModule,
    // MatRadioModule,
    // MatSlideToggleModule,
    // MatProgressSpinnerModule,
    // SharedModule,
    // PopupModule,
    // DirectivesModule,
    // ClipboardModule,
  ],
  providers: [
    DecimalPipe
  ]
})
export class CardsModule {
}
