import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class TooltipService {
  constructor(private sanitizer: DomSanitizer) {}

  createTooltip(content: SafeHtml): HTMLElement {
    const sanitizedContent = this.sanitizer.sanitize(SecurityContext.HTML, content) as string;
    const tooltipElement = document.createElement('div');
    tooltipElement.className = 'tooltip showTooltip';
    tooltipElement.innerHTML = sanitizedContent;
    return tooltipElement;
  }
}
