import {
  ChangeDetectionStrategy,
  Component,
  Input,
} from '@angular/core';

@Component({
  selector: 'app-release-date-view',
  templateUrl: './release=date-view.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReleaseDateViewComponent{
  @Input() date: string;
  @Input() isExclusive: boolean = false;
}
