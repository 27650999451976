import {Directive, ElementRef, Input, HostListener, SecurityContext} from '@angular/core';
import {IRevenueItem} from "@app/pages/revenue/types/revenue-response.interface";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {EXCLUSIVE_STATUS, QUESTION_MARK} from "@app/pages/revenue/constant/questionary";
import {TooltipService} from "@services/tooltip.service";

@Directive({
  selector: '[appTooltip]'
})
export class TooltipDirective {
  @Input() appTooltip: IRevenueItem;

  private tooltipElement: HTMLElement;

  constructor(private el: ElementRef, private sanitizer: DomSanitizer, private tooltipService: TooltipService) {
  }

  @HostListener('mouseenter') onMouseEnter() {
    const content = this.getTooltipText();
    this.tooltipElement = this.tooltipService.createTooltip(content);
    this.el.nativeElement.appendChild(this.tooltipElement);
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.removeTooltip();
  }

  rightsContent(rights: string) {
    switch (rights) {
      case 'Publishing Rights':
        return this.sanitizer.bypassSecurityTrustHtml(QUESTION_MARK.publish);
      case 'Master Rights':
        return this.sanitizer.bypassSecurityTrustHtml(QUESTION_MARK.master);
      default:
        return this.sanitizer.bypassSecurityTrustHtml(
          QUESTION_MARK.master + `<br><br>` + QUESTION_MARK.publish);
    }
  }

  private getTooltipText(): SafeHtml {
    const {isExclusiveProject, rights} = this.appTooltip;
    if (isExclusiveProject) {
      return this.sanitizer.bypassSecurityTrustHtml(EXCLUSIVE_STATUS);
    }
    return this.rightsContent(rights);

  }
  private removeTooltip() {
    if (this.tooltipElement) {
      this.tooltipElement.remove();
      this.tooltipElement = null;
    }
  }
}
